import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import DocumentiUtili from '../components/documenti-utili'
import PhotoGallery from '../components/photogallery'
import Video from '../components/video'

import utils from '../utility/utils.js'
import Layout from "../components/layout"
import { graphql } from "gatsby"

class NewsTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulNewsEdEventi;
    let breadcrumb = [{"title":"News ed eventi","link":"/news-ed-eventi/"}];
    /*if (data.argomento === 'Rassegna stampa') {
      breadcrumb = [{"title":"Rassegna stampa","link":"/rassegna-stampa/"}];
    }*/
    breadcrumb.push({"title":`${data.titolo}`});

    return (
      <Layout className="container" location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo}
          meta={[
            { property: 'og:site_name', content: 'Ospedale veterinario universitario'},
            { property: 'og:type', content: 'article' },
            { property: 'og:title', content: `${data.titolo}` },
            { property: 'og:description', content:  `${data.abstractNews.childMarkdownRemark.excerpt}`},
            { property: 'og:image', content: `${data.immagine?'http:'+data.immagine.file.url:''}` }
          ]}
          />
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <article className="news news--detail">
                <h1>
                  {data.titolo}
                </h1>
                <div>
                  {!!data.immagine &&
                    <figure className="news__figure">
                      <img src={data.immagine.file.url} alt={data.immagine.title} className="news__img" />
                    </figure>
                  }
                  <span className="news__date">{utils.formatDate(data.data)} | {data.argomento}</span>
                  {!!data.descrizione &&
                    <div dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                    </div>
                  }
                  
                  <div className="clearfix"></div>
                </div>
                {!!data.fotogallery &&
                  <div>
                    <h2 className="servizi__subject">Fotogallery</h2>
                    <PhotoGallery data={data.fotogallery}/>
                  </div>
                }
                 {!!data.video &&
                  <div>
                    <h2 className="servizi__subject">Video</h2>
                    <Video data={data.video}/>
                  </div>
                }
              </article>
            </div>  
            <div className="col-md-4">
              {!!data.allegato &&
                <DocumentiUtili data={data.allegato}/>
              }
             
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default NewsTemplate;

export const pageQuery = graphql`query NewsDetailQuery($contentful_id: String!) {
  contentfulNewsEdEventi(contentful_id: {eq: $contentful_id}) {
    id
    titolo
    friendlyUrl
    argomento
    data
    abstractNews {
      childMarkdownRemark {
        excerpt
      }
    }
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    immagine {
      title
      file {
        url
      }
    }  
    allegato {
      title
      file {
        url
      }
    }
    fotogallery {
      ...PhotoGalleryFragment
    }
    video {
      title 
      file {
        url
      }
    }
  }
}
`;
